<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'Nama'">
                    {{ props.row.name }}
                </span>
                <span v-else-if="props.column.label == 'Data'">
                    <div v-for="(value, index) in props.row.stringData" v-bind:key="index">
                        <b>{{ value.title }}</b> : {{ value.value }}
                    </div>
                </span>
                <span v-else-if="props.column.field == 'action'">
                    <a v-on:click="deleteUser(props.row.id)" class="btn btn-danger text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        props: ['device', 'id', 'campaign'],
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Nama",
                            field: "data",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Nomor",
                            field: "number",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Data",
                            field: "data",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "30%",
                        },
                        {
                            label: "Bergabung pada",
                            field: "joined_at",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        perPage: 10,
                    },
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("refresh_table", this.fnRefreshTable);
        },
        mounted() {
            this.fnRefreshTable = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fnRefreshTable);
            
            this.loadItems();
        },
        methods: {
            async deleteContact(id) {
                try {
                    let response = await this.$store.dispatch("chat_widget_delete", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                try {
                    let r = await this.getFromServer(this.table.serverParams)
                    let response = r.data.data.users

                    let campaignData = this.campaign
                    let syntax = campaignData.registrasi.split("#")
                    syntax.shift()

                    let nameIndex = 0
                    for(let j = 0;j < syntax.length;j++){
                        if(syntax[j]?.toLowerCase() == "nama" || syntax[j]?.toLowerCase() == "name"){
                            nameIndex = j
                            break
                        }else{
                            nameIndex = -1
                            // response[i].name = "-"
                        }
                    }

                    for(let i = 0;i < response.length;i++){
                        let userData = JSON.parse(response[i].data)

                        if(nameIndex != -1){
                            response[i].name = userData[nameIndex]
                        }else{
                            response[i].name = ""
                        }

                        let stringData = []
                        for(let k = 0;k < syntax.length;k++){
                            stringData.push({
                                title: syntax[k],
                                value: userData[k]
                            })
                        }

                        response[i].stringData = stringData

                        response[i].joined_at = moment(response[i].joined_at).format("YYYY-MM-DD")
                    }
                    this.table.total_records = r.data.data.total_records;
                    this.table.rows = response;
                } catch (err) {
                    console.log(err)
                    this.$toast.error("Too many request");
                }
            },
            async getFromServer(param) {
                param.id = this.id
                let get = await this.$store.dispatch("campaign_user_list", param)
                return get
            },
            async deleteUser(id) {
                try {
                    let response = await this.$store.dispatch("campaign_user_delete", {
                        campaign_id: this.id,
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>
