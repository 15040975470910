<template>
    <div>
        <form v-on:submit.prevent="updateCampaign" enctype="multipart/form-data">
            <label> ID Campaign </label>
            <input type="text" class="form-control" v-model="form.id" readonly>
            <br>
            <label> Pengirim </label>
            <multiselect v-model="form.device" tag-placeholder="Pilih device"
                placeholder="Pilih device" label="name" track-by="id" :options="device"
                :multiple="false"></multiselect>
            <br>
            <label for="">Nama Campaign</label>
            <input type="text" class="form-control" v-model="form.name" readonly>
            <br>
            <label for="">Nomor Device</label>
            <input type="text" class="form-control" v-model="form.number" required>
            <br>
            <label for="">Syntax Registrasi</label>
            <input type="text" class="form-control" v-model="form.syntax" placeholder="Contoh : registrasi#nama#alamat" readonly>
            <br>
            <label> Sync Google Contact </label>
            <br>
            <label class="switch">
                <input type="checkbox" v-model="form.google_contact_sync" :checked="form.google_contact_sync">
                <span class="slider round"></span>
            </label>
            <br>
            <button type="submit" class="btn btn-success btn-block">Simpan</button>
        </form>        
    </div>
</template>

<script>
    //import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['device', 'id', 'campaign'],
        components: {
            Multiselect,
        },
        data() {
            return {
                form: {
                    id: "",
                    device: [],
                    name: "",
                    number: "",
                    syntax: "",
                    message: "",
                    url: "",
                    form_type: 0,
                    header: "",
                },
                syntax_old: "",
                syntax_explain: [],
                available_variable: "",
                quill_disabled: true
            };
        },
        watch: {
            form : {
                handler(val){
                    if(val.syntax != this.syntax_old){
                        let syntax = val.syntax
                        let split = syntax.split("#")

                        this.syntax_explain = split
                        this.available_variable = ''

                        for(let i = 1;i < split.length;i++){
                            this.available_variable += '[' + split[i] + ']'
                            
                            if(split[i+1]){
                                this.available_variable += ', '
                            }
                        }

                        this.syntax_old = val.syntax
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            this.getCampaign()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getCampaign() {
                let res = this.campaign

                this.form.id = res.id
                this.form.name = res.name
                this.form.number = res.number
                this.form.syntax = res.registrasi
                this.form.message = res.welcome_message
                this.form.url = 'https://localhost/starsender/public/subscribe/' + res.id
                this.form.form_type = res.form_type
                this.form.header = res.header ? this.decodeEscapedHTML(res.header) : ""
                this.form.google_contact_sync = (res.google_contact_sync == 1) ? true : false

                for(let i = 0;i < this.device.length;i++){
                    if(this.device[i].id == res.device_id){
                        this.form.device = this.device[i]
                    }
                }

                this.quill_disabled=false
            },
            async updateCampaign(){
                try{
                    let data = {
                        id: this.form.id,
                        device: this.form.device,
                        number: this.form.number,
                        google_contact_sync: this.form.google_contact_sync ? 1 : null
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async updateForm(){
                try{
                    let data = {
                        id: this.form.id,
                        form_type: parseInt(this.form.form_type),
                        header: this.form.header,
                    }

                    let update_campaign = await this.$store.dispatch('campaign_update_form', data)
                    this.$toast.success(update_campaign.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            copyURL(){
                navigator.clipboard.writeText(this.form.url)
                this.$toast.success("URL berhasil disalin")
            },
            decodeEscapedHTML(str){
                return str.replaceAll(/&lt;/g,'<').replaceAll(/&gt;/g,'>').replaceAll(/&amp;/g,'&');
            }
        },
    };
</script>

<style scoped>
    @import "~vue-wysiwyg/dist/vueWysiwyg.css";
    

    table {
        border: 1px solid;
    }

    .editor {
        height: 100%;
    }
</style>


<style scoped>
    .nav-link{
        cursor: pointer !important;
    }

    .thick-border {
        border: 1.5px solid #6c6c6c !important;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 17px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 17px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>