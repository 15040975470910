<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Campaign
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="repairCampaign()" class="btn px-15 btn-info text-white"
                                style="display: inline-flex; margin: 5px">
                                Repair Pesan Registrasi (Dari Migrasi V2)
                            </a>
                            <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Campaign
                            </a>
                            <a v-on:click="toggleModal('broadcast_campaign')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-broadcast-tower fs-16"></i>
                                Broadcast
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="campaignQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas campaign maksimal anda adalah <b>Unlimited</b> campaign</p>
                </div>
            </div>
            <div v-else-if="campaignQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas campaign maksimal anda adalah <b>{{ campaignQuota }}</b> campaign</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas campaign maksimal anda adalah <b>{{ campaignQuota }}</b> campaign</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <CampaignTable />
                    </div>
                </div>
            </div>
            <modal name="new_campaign" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddCampaign :device="device" />
                </div>
            </modal>
            <modal name="broadcast_campaign" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <SendBroadcast :device="device" ></SendBroadcast>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import CampaignTable from "@/components/tables/Campaign.vue";
    import AddCampaign from "@/components/forms/campaign/Add.vue";
    import SendBroadcast from "@/components/forms/campaign/SendBroadcast.vue";

    export default {
        components: {
            CampaignTable,
            AddCampaign,
            SendBroadcast
        },
        data() {
            return {
                device: [],
                exportJson: [],
                campaignQuota: 0
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.campaignQuota = this.$store.state.auth.package.max_campaign

            this.getDevice({
                status: "all"
            })
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async repairCampaign() {
                try {                        
                    this.$toast.success("Repair pesan registrasi campaign sedang diproses");
                    await this.$store.dispatch('campaign_repair')
                    // this.$toast.success(response.data.message);
                    this.$root.$emit("refresh_table", true)
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>
