<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <router-link :to="'/campaign/detail?id=' + props.row.id">
            <a class="btn btn-primary text-white margin5">Detail</a>
          </router-link>
          <a v-on:click="deleteCampaign(props.row.id)" class="btn btn-danger text-white margin5">
            Hapus
          </a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [{
              label: "Nama Campaign",
              field: "name",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Syntax Registrasi",
              field: "registrasi",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "50%",
            },
            {
              label: "Jumlah Anggota",
              field: "user_count",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            }, ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteCampaign(id) {
        try {
          let response = await this.$store.dispatch("campaign_delete", {
            id: id
          });
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          }, ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.campaigns;
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        return await this.$store.dispatch("campaign_list", param);
      },
    },
  };
</script>

<style scoped>
  .margin5 {
    margin: 5px;
  }
</style>